<template>
  <span v-html="formattedText"></span>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    numberOfCharacters: {
      type: Number
    },
    textSize: {
      type: String,
      default: "14px"
    },
    ellipsis: {
      type: String,
      default: "..."
    }
  },

  computed: {
    formattedText() {
      let subString = this.text.substring(0, this.numberOfCharacters);

      return this.text.length <= this.numberOfCharacters
        ? subString
        : subString + this.ellipsis;
    }
  }
};
</script>
