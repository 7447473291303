<template>
  <div class="w-full px-4 py-4">
    <div class="relative flex h-full overflow-hidden bg-white rounded-xl">
      <div
        class="w-full h-full py-8 overflow-y-auto bg-white border rounded rounded-r-none sm:p-4 border-gray-type-12 lg:w-2/6"
        :class="selectedNotification ? 'hidden lg:block' : 'block'"
      >
        <div
          @click="showNotificationDetails(notification)"
          class="relative flex items-center justify-between px-4 py-5 transition-all duration-200 ease-in-out rounded-lg cursor-pointer"
          :class="
            isSelectedItem(notification)
              ? 'bg-gray-type-2 text-white'
              : 'bg-white '
          "
          v-for="notification in notifications"
          :key="notification.id"
        >
          <div
            class="absolute top-0 left-0 w-2 h-2 mt-2 rounded-full bg-red-type-1"
            v-if="!notification.is_rad"
            style="left: 50%"
          ></div>
          <div class="flex items-center justify-between w-full">
            <div class="flex items-center">
              <svg
                class="w-10 h-10"
                viewBox="0 0 56 56"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill-rule="nonzero" fill="none">
                  <path
                    d="M10.71 5.823c11.962-9.289 29.136-7.237 38.581 4.528l.285.36c9.288 11.961 7.236 29.135-4.53 38.58l-.36.284c-11.96 9.289-29.133 7.237-38.58-4.528l-.283-.36C-3.56 32.605-1.371 15.205 10.71 5.823z"
                    fill="#20E682"
                  />
                  <path
                    d="M41.71 23.967a8.667 8.667 0 00-12.088-2.131l-6.323 4.427a1.653 1.653 0 01-.661.953 1.68 1.68 0 01-2.26-.307l-.042-.052a.73.73 0 01-.04-.053l-.036-.055-.034-.057a1.68 1.68 0 011.586-2.526l10.64-7.45-.2-8.877L15.82 19.345a8.678 8.678 0 109.956 14.218l6.323-4.428.006-.02c.097-.359.311-.674.609-.897.015-.013.03-.023.047-.035a1.677 1.677 0 012.3.359l.039.053c.012.018.025.036.037.056l.034.056a1.68 1.68 0 01-1.583 2.526l-10.642 7.452.2 8.874 16.43-11.505a8.68 8.68 0 002.134-12.087z"
                    fill="#141615"
                  />
                </g>
              </svg>
              <div class="ml-2">
                <div class="text-sm font-bold text-dark-type-3">
                  {{ notification.title }}
                </div>
                <ellipsis
                  class="text-xs font-medium text-dark-type-4"
                  :text="notification.description"
                  :number-of-characters="50"
                ></ellipsis>
              </div>
            </div>
            <div class="self-end font-medium text-xxs">20m</div>
          </div>
        </div>
      </div>
      <div
        class="self-stretch flex-1"
        :class="selectedNotification ? 'block' : 'hidden lg:block'"
      >
        <template v-if="selectedNotification">
          <div class="px-6 py-14 md:py-4 lg:p-10">
            <button class="lg:hidden" @click="selectedNotification = null">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-6 h-6 mb-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
            </button>
            <div class="flex items-center justify-between">
              <div class="flex items-center w-full lg:w-auto">
                <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                  <g fill-rule="nonzero" fill="none">
                    <path
                      d="M10.71 5.823c11.962-9.289 29.136-7.237 38.581 4.528l.285.36c9.288 11.961 7.236 29.135-4.53 38.58l-.36.284c-11.96 9.289-29.133 7.237-38.58-4.528l-.283-.36C-3.56 32.605-1.371 15.205 10.71 5.823z"
                      fill="#20E682"
                    />
                    <path
                      d="M41.71 23.967a8.667 8.667 0 00-12.088-2.131l-6.323 4.427a1.653 1.653 0 01-.661.953 1.68 1.68 0 01-2.26-.307l-.042-.052a.73.73 0 01-.04-.053l-.036-.055-.034-.057a1.68 1.68 0 011.586-2.526l10.64-7.45-.2-8.877L15.82 19.345a8.678 8.678 0 109.956 14.218l6.323-4.428.006-.02c.097-.359.311-.674.609-.897.015-.013.03-.023.047-.035a1.677 1.677 0 012.3.359l.039.053c.012.018.025.036.037.056l.034.056a1.68 1.68 0 01-1.583 2.526l-10.642 7.452.2 8.874 16.43-11.505a8.68 8.68 0 002.134-12.087z"
                      fill="#141615"
                    />
                  </g>
                </svg>
                <div class="ml-4">
                  <div class="text-sm font-bold text-gray-type-2">
                    {{ selectedNotification.title }}
                  </div>
                  <div class="mt-2 text-sm text-gray-800 lg:hidden">
                    {{ $filters.date(selectedNotification.created_at) }}
                  </div>
                </div>
              </div>
              <div>
                <div class="hidden text-sm text-gray-800 lg:block">
                  {{ $filters.date(selectedNotification.created_at) }}
                </div>
              </div>
            </div>
            <hr class="mt-8" />
            <div
              class="mt-10 text-xs font-medium text-gray-type-2"
              style="line-height: 1.583"
              v-html="selectedNotification.content"
            ></div>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center h-full">
            <img src="@/assets/img/email-sent.svg" alt />
            <div class="mt-5 text-sm font-medium text-center text-dark-type-4">
              Click on a notification to view content
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Ellipsis from "@/components/Ellipsis";

export default {
  components: {
    Ellipsis,
  },
  data() {
    return {
      selectedNotification: null,
      notifications: [
        {
          id: "a09d5e99-9289-4878-a88e-0fa3ef877aa7",
          title: "Shuttlers Admin",
          description: "Please do not forget the safety belts",
          content:
            "The U.S’s Fed, at its recent meeting, voted to leave its benchmark interest rate unchanged at near zero per cent, which was widely anticipated, and released projections showing that a majority of policymakers expect it to stay there through 2023.",
          read_at: null,
          is_read: false,
          created_at: new Date(),
        },
        {
          id: "8fc39361-24f9-456e-b6ed-32bce354ba35",
          title: "Shuttlers Admin",
          description: "Please do not forget the safety belts",
          content:
            "The U.S’s Fed, at its recent meeting, voted to leave its benchmark interest rate unchanged at near zero per cent, which was widely anticipated, and released projections showing that a majority of policymakers expect it to stay there through 2023.",
          read_at: null,
          is_read: false,
          created_at: new Date(),
        },
      ],
    };
  },
  methods: {
    showNotificationDetails(item) {
      this.selectedNotification = item;

      this.showDetails = true;

      if (!item.is_read) {
        // this.$store.dispatch("app/markNotificationAsRead", {
        //   notificationId: item.id,
        // });
      }
    },
    isSelectedItem(item) {
      if (this.selectedNotification) {
        return this.selectedNotification.id === item.id;
      } else {
        return false;
      }
    },
  },
};
</script>